<template>
  <div
    v-on="$listeners"
    class="
      border cursor-pointer duration-100 flex justify-between
      mt-3 p-5 rounded-sm transition w-full
    "
    :class="[
      isSelected
        ? 'bg-onboarding-600 border-onboarding-600 text-white'
        : (
          isGtBank
            ? 'bg-onboarding-900 border-onboarding-900 text-white hover:bg-orange-700 hover:border-orange-700'
            : 'border-onboarding-800 text-onboarding-600 hover:bg-blue-100'
        ),
    ]"
  >
    <div class="flex flex-col mb-2">
      <h4 class="text-12 mt-2 font-bold">
        <slot name="title" />
      </h4>
      <h4 class="text-10 mt-1 font-thin">
        <slot name="body" />
      </h4>
    </div>
    <div
      class="rounded-full mt-1 h-8 w-8 items-center flex justify-center"
      :class="[
        isSelected
          ? 'bg-green-500'
          : (
            isGtBank ? 'bg-gtb' : 'bg-onboarding-800'
          ),
      ]"
    >
      <vector :src="assets.icons.sm.chevronRight" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BankAccountVerificationSelectionOption',
  props: {
    isGtBank: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    isVerified: {
      type: Boolean,
      default: false
    },
  },
};
</script>
