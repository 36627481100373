var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._g({staticClass:"\n    border cursor-pointer duration-100 flex justify-between\n    mt-3 p-5 rounded-sm transition w-full\n  ",class:[
    _vm.isSelected
      ? 'bg-onboarding-600 border-onboarding-600 text-white'
      : (
        _vm.isGtBank
          ? 'bg-onboarding-900 border-onboarding-900 text-white hover:bg-orange-700 hover:border-orange-700'
          : 'border-onboarding-800 text-onboarding-600 hover:bg-blue-100'
      ) ]},_vm.$listeners),[_c('div',{staticClass:"flex flex-col mb-2"},[_c('h4',{staticClass:"text-12 mt-2 font-bold"},[_vm._t("title")],2),_c('h4',{staticClass:"text-10 mt-1 font-thin"},[_vm._t("body")],2)]),_c('div',{staticClass:"rounded-full mt-1 h-8 w-8 items-center flex justify-center",class:[
      _vm.isSelected
        ? 'bg-green-500'
        : (
          _vm.isGtBank ? 'bg-gtb' : 'bg-onboarding-800'
        ) ]},[_c('vector',{attrs:{"src":_vm.assets.icons.sm.chevronRight}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }